import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import NavBar01 from "./layout/NavBar01";
import NavBar02 from "./layout/NavBar02";
import Home from "./page/Home";

function App() {
  return (
    <Router customClass="min-height">
      <NavBar01 />
      <NavBar02 />
      <Routes customClass="min-height">
       <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;

/*
<div className="App">
<p>CICLO TECNOLOGIA</p>
</div>
*/

/*
function App() {
  return (
    <Router customClass="min-height">
      <NavBar />
      <Container>
        <Routes customClass="min-height">
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />  
          <Route path="/newproject" element={<NewProject />} />  
          <Route path="/company" element={<Company />} />
          <Route path="/contact" element={<Contact />} />  
        </Routes>
      </Container>
      <Footer />
    </Router>
  )
}
*/