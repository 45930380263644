import {Link} from 'react-router-dom';
//import Container from './Container'; 
import { FaWhatsapp } from 'react-icons/fa';

import styles from "./NavBar01.module.css"

function NavBar01() {
  return(
    <nav className={styles.navbar}>
      <ul className={styles.list}>
        <li className={styles.item}>
          <Link to="/">(27) 3200-3510 </Link>
        </li> 
        <li className={styles.fawhatsapp}>
          <FaWhatsapp />
        </li>
        <li className={styles.item}>
          <Link to="/company">(27) 3010-3510)</Link>
        </li>
      </ul>
    </nav>
  )
}

export default NavBar01;
