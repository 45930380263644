import {Link} from 'react-router-dom';
//import Container from './Container'; 
import styles from "./NavBar02.module.css"
import logo from '../img/ciclo-p.png'

function NavBar02() {
  return(
    <nav className={styles.navbar}>
      <Link to="/"><img className={styles.img}src={logo} alt="ciclo" /></Link>
      <ul className={styles.list}>
        <li className={styles.item}>
          <Link to="/">Home</Link>
        </li> 
        <li className={styles.item}>
          <Link to="/projects">A Empresa</Link>
        </li>
        <li className={styles.item}>
          <Link to="/company">Soluções</Link>
        </li>
        <li className={styles.item}>
          <Link to="/contact">Contato<br></br></Link>
        </li>
        <li className={styles.item}>
          <Link to="/contact">Contato</Link>
        </li>
      </ul>
    <button className={styles.btn}>Login</button>
    </nav>
  )
}

export default NavBar02;
