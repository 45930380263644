import styles from './Home.module.css';
import logo from '../img/ciclo-p.png';
//import savings from '../../img/savings.svg';
//import styles_btn from '../layout/LinkButton.module.css';

function Home() {
  return (
    <section className={styles.home_container}>
      <h2>Bem-vindo a <span>CICLO TECNOLOGIA</span></h2>
      <img src={logo} alt="Ciclo" />
    </section>
  )
}

export default Home;

/*
function Home() {
  return (
    <section className={styles.home_container}>
      <h1>Bem-vindo ao <span>Costs</span></h1>
      <p>Comece a gerenciar os seus projetos agora mesmo !</p>
      <a href="/newproject"><button className={styles_btn.btn}>Criar Projeto</button></a>
      <img src={savings} alt="Costs" />
    </section>
  )
}
*/